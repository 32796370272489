import DropDown from "app/elements/dropdown";
import DateToTsConverter from "app/pages/dateToTsConverter";
import SecToDHM from "app/pages/secToDHM";
import StartAndEndToYMD from "app/pages/startAndEndToYMD";
import TsToDateConverter from "app/pages/tsToDateConverter";
import { epochConverterMenu } from "helpers/dropdown";
import { Routes } from "helpers/routes";
import React, { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";

export default function EpochConverter(): ReactElement {
  const [dropdownValue, setDropdownValue] = useState(epochConverterMenu[1]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [pathName, setPathName] = useState("");

  const handleDropdownChange = (selectedVal: {
    name: string;
    value: Routes;
  }) => {
    setDropdownValue(selectedVal);
    setOpenDropdown(false);
    navigate("/" + selectedVal.value);
  };

  const handleDropdownOpen = () => {
    setOpenDropdown(!openDropdown);
  };

  useEffect(() => {
    if (location) {
      let tmp = location.pathname.split("/")[1];
      setPathName(tmp);
      switch (tmp) {
        case Routes.timeStampToDate:
        case Routes.home:
          setDropdownValue(epochConverterMenu[1]);
          break;
        case Routes.dateToTimeStamp:
          setDropdownValue(epochConverterMenu[0]);
          break;
        case Routes.startAndEndToYMD:
          setDropdownValue(epochConverterMenu[3]);
          break;
        case Routes.secToDHM:
          setDropdownValue(epochConverterMenu[2]);
          break;
        default:
          setDropdownValue(epochConverterMenu[1]);
      }
    }
  }, [location]);

  return (
    <React.Fragment>
      <DropDown
        handleChange={handleDropdownChange}
        selectedValue={dropdownValue}
        handleDropdownOpen={handleDropdownOpen}
        dropdownOptions={epochConverterMenu}
        openDropdown={openDropdown}
      />
      {(pathName === Routes.timeStampToDate || pathName === Routes.home) && (
        <TsToDateConverter />
      )}
      {pathName === Routes.dateToTimeStamp && (
        <DateToTsConverter
          handleParentDropdown={setOpenDropdown}
          parentDropdownOpened={openDropdown}
        />
      )}
      {pathName === Routes.secToDHM && <SecToDHM />}
      {pathName === Routes.startAndEndToYMD && (
        <StartAndEndToYMD
          handleParentDropdown={setOpenDropdown}
          parentDropdownOpened={openDropdown}
        />
      )}
    </React.Fragment>
  );
}
