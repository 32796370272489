import epochLogo from "./epoch-logo.png";
import md5Logo from "./md5-logo.png";
import navbarLogo from "./navbar-logo.png";
import sha1Logo from "./sha1-logo.png";
import base64Logo from "./base64-logo.png";

import containerCircle from "./container-circle.png";

import menuIconMob from "./menu-icon-mob.png";
import menuIcon from "./menu-icon.png";
import tickIcon from "./charm_tick.png";
import selectDownIcon from "./select-down.png";
import selectupIcon from "./select-up.png";
import copyIcon from "./copy-icon.png";

export const images = {
  epochLogo,
  md5Logo,
  navbarLogo,
  sha1Logo,
  base64Logo,
  menuIconMob,

  containerCircle,

  menuIcon,
  tickIcon,
  selectDownIcon,
  selectupIcon,
  copyIcon,
};
