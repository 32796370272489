import { ReactElement } from "react";
import "./style.css";

export interface IInputProps {
  placeholder?: string;
  subtitle?: string;
  handleChange: (event: any) => void;
  inputValue: string;
  type?: string;
  regexValidation?: boolean;
}

export default function Input(props: IInputProps): ReactElement {
  const {
    placeholder,
    subtitle,
    handleChange,
    inputValue,
    type = "text",
    regexValidation = false,
  } = props;

  return (
    <div className="inputContainer">
      <input
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        value={inputValue}
        style={{ width: subtitle ? "70%" : "100%" }}
        pattern={type === "number" ? "[0-9]*" : ""}
        inputMode={type === "text" ? "text" : "numeric"}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key) && type === "number") {
            event.preventDefault();
          }

          if (regexValidation) {
            const regex = new RegExp(/^[A-Za-z0-9=+/]+$/i);

            if (!regex.test(event.key)) event.preventDefault();
          }
        }}
      />
      <span className="subtitle">{subtitle}</span>
    </div>
  );
}
