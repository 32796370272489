import Button from "app/elements/button";
import Input from "app/elements/input";
import { IResultCardProps } from "app/elements/resultCard";
import { formatOptions, formatUtcDate } from "helpers/dateTimeConverter";
import moment from "moment";
import React, { ReactElement, useRef, useState } from "react";
import "./style.css";
import ResultSection from "app/components/resultSection";

export default function TsToDateConverter(): ReactElement {
  const [inputValue, setInputValue] = useState<string>("");
  const [results, setResults] = useState<IResultCardProps[]>([]);
  const [resultSubTile, setResultSubtitle] = useState<string>("");
  const tsResultRef = useRef<any>(null);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
    setResults([]);
  };

  function getCurrentTime() {
    var date = new Date();
    return (date.getTime() - date.getMilliseconds()) / 1000;
  }

  const handleButtonClick = () => {
    const currentTSLength = getCurrentTime()?.toString()?.length;
    var updatedinputTs = Number(inputValue);

    if (inputValue.length > currentTSLength + 1) {
      if (inputValue.length - currentTSLength > 6) {
        updatedinputTs = Number(inputValue) / 1000000;
        setResultSubtitle("Assuming that this timestamp is in nanoseconds");
      } else if (inputValue.length - currentTSLength > 4) {
        updatedinputTs = Number(inputValue) / 1000;
        setResultSubtitle("Assuming that this timestamp is in microseconds");
      } else {
        updatedinputTs = Number(inputValue);
        setResultSubtitle("Assuming that this timestamp is in milliseconds");
      }
    } else {
      updatedinputTs = Number(inputValue) * 1000;
      setResultSubtitle("Assuming that this timestamp is in seconds");
    }

    var inputDate = new Date(updatedinputTs);
    const tzDate = inputDate
      .toLocaleString(undefined, formatOptions)
      .split(" at ");

    const utcDate = formatUtcDate(inputDate);

    if (tsResultRef && tsResultRef.current) {
      tsResultRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    setResults([
      {
        heading: "GMT",
        subtitle: utcDate,
      },
      {
        heading: "Local",
        subtitle: tzDate[1]
          ? `${tzDate[0]} \n${tzDate[1]?.trim()?.toUpperCase()}`
          : tzDate[0],
      },
      {
        heading: "Relative",
        subtitle: moment(inputDate).fromNow(true) + " ago",
      },
    ]);
  };

  return (
    <React.Fragment>
      <div className="tsToDateWrapper">
        <Input
          placeholder="Type here"
          handleChange={handleInputChange}
          inputValue={inputValue}
          type="number"
        />

        <div className="tsCta">
          <Button
            buttonText="Convert"
            handleButtonClick={handleButtonClick}
            isDisabled={!inputValue}
          />
        </div>
        <p className="tsInfo">
          Supports Unix timestamps in seconds, milliseconds, microseconds and
          nanoseconds.
        </p>
      </div>

      {results.length > 0 && inputValue.length > 0 && (
        <div className="pageResult" ref={tsResultRef}>
          <ResultSection resultSubtitle={resultSubTile} resultList={results} />
        </div>
      )}
    </React.Fragment>
  );
}
