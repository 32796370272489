import { images } from "assets/images";
import { ReactElement, useState } from "react";
import "./style.css";

export interface IResultCardProps {
  heading?: string;
  subtitle?: string;
  customSubtitleStyles?: any;
  customCardStyles?: any;
}

export default function ResultCard(props: IResultCardProps): ReactElement {
  const { heading, subtitle, customSubtitleStyles, customCardStyles } = props;
  const [showModal, setShowModal] = useState(false);

  function handleCopyClick() {
    navigator.clipboard.writeText(subtitle || "");
    setShowModal(true);

    setTimeout(() => {
      setShowModal(false);
    }, 1000);
  }

  return (
    <div className="cardContainer" style={customCardStyles}>
      <div className="heading">{heading}</div>
      <div className="subtitle" style={customSubtitleStyles}>
        {subtitle}

        <img
          src={images.copyIcon}
          alt="Copy"
          title="Copy"
          width={24}
          height={24}
          onClick={handleCopyClick}
        />
      </div>
      {showModal && (
        <div className="popup">
          <p>Copied!</p>
        </div>
      )}
    </div>
  );
}
