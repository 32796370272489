import Button from "app/elements/button";
import Input from "app/elements/input";
import { IResultCardProps } from "app/elements/resultCard";
import React, { ReactElement, useRef, useState } from "react";
import "./style.css";
import ResultSection from "app/components/resultSection";

export default function SecToDHM(): ReactElement {
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState<IResultCardProps[]>([]);
  const secResultRef = useRef<any>(null);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
    setResults([]);
  };

  const handleButtonClick = () => {
    const seconds = Number(inputValue);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    if (secResultRef && secResultRef.current) {
      secResultRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    setResults([
      {
        heading: "Days",
        subtitle: d + "",
        customSubtitleStyles: { fontSize: "40px", lineHeight: "48px" },
      },
      {
        heading: "Hours",
        subtitle: h + "",
        customSubtitleStyles: { fontSize: "40px", lineHeight: "48px" },
      },
      {
        heading: "Minutes",
        subtitle: m + "",
        customSubtitleStyles: { fontSize: "40px", lineHeight: "48px" },
      },
      {
        heading: "Seconds",
        subtitle: s + "",
        customSubtitleStyles: { fontSize: "40px", lineHeight: "48px" },
      },
    ]);
  };

  return (
    <React.Fragment>
      <div className="secToDHMWrapper">
        <Input
          placeholder="Type here"
          handleChange={handleInputChange}
          inputValue={inputValue}
          type="number"
        />
        <div className="tsCta">
          <Button
            buttonText="Convert"
            handleButtonClick={handleButtonClick}
            isDisabled={!inputValue}
          />
        </div>
      </div>
      {results.length > 0 && inputValue.length > 0 && (
        <div className="pageResult" ref={secResultRef}>
          <ResultSection resultList={results} />
        </div>
      )}
    </React.Fragment>
  );
}
