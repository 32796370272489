export enum Routes {
  home = "",
  dateToTimeStamp = "date-to-timestamp",
  timeStampToDate = "timestamp-to-date",
  secToDHM = "seconds-to-day-hour-mins",
  startAndEndToYMD = "start-and-end-of-year",
  md5Hash = "md5-hash-generator",
  sha1 = "sha1-converter",
  sha256 = "sha256-converter",
  sha512 = "sha512-converter",
  base64Encode = "base64-encode",
  base64Decode = "base64-decode",
}
