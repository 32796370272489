import { ReactElement } from "react";
import "./style.css";

export interface IInfoSectionProps {
  infoDetails: string;
  infoHeader: string;
}

export default function InfoSection(props: IInfoSectionProps): ReactElement {
  const { infoDetails, infoHeader } = props;

  return (
    <div className="info-container">
      <div className="divider" />

      <div className="footer-header info-title-container">{infoHeader}</div>

      <div className="info-wrapper">{infoDetails}</div>
    </div>
  );
}
