import { ICurrentComponentProps } from "app/components/siteNavbar";
import { Routes } from "./routes";
import { images } from "assets/images";

export const epochConverterMenu = [
  { name: "Human date to Timestamp", value: Routes.dateToTimeStamp },
  { name: "Timestamp to Human date", value: Routes.timeStampToDate },
  {
    name: "Convert seconds to days, hours and minutes",
    value: Routes.secToDHM,
  },
  {
    name: "Start and end of the year/month/day",
    value: Routes.startAndEndToYMD,
  },
];

export const timeZoneMenu = [
  { name: "GMT", value: "gmt" },
  { name: "Local time", value: "localTime" },
];

export const hamburgerComponents: ICurrentComponentProps[] = [
  {
    id: "epoch",
    logo: images.epochLogo,
    showUnixTime: true,
    title: "Epoch Converter",
    route: Routes.timeStampToDate,
    routeArray: [
      Routes.timeStampToDate,
      Routes.dateToTimeStamp,
      Routes.startAndEndToYMD,
      Routes.secToDHM,
      Routes.home,
    ],
    footerHeader: "About Epoch Converter",
    footerText:
      "A Unix period timestamp is a representation of time in seconds or milliseconds. It measures the time in seconds (or milliseconds) from January 1, 1970 UTC (or GMT) to a specific time, which is called a timestamp, for example. The tools on this page will allow you to make any changes to the ISO date, human readable date string in UTC time, and your current time. Online unix time conversion tool has the functionality to convert human readable time zone time into millisecond and second timestamps.",
  },
  {
    id: "md5",
    logo: images.md5Logo,
    showUnixTime: false,
    title: "MD5 Hash Generator",
    route: Routes.md5Hash,
    routeArray: [Routes.md5Hash],
    footerHeader: "About MD5 Hash",
    footerText:
      "MD5 hash function generators can be used as passwords or keys and can be used to store financial, insurance or business information, copies, credit card information, etc. Useful when creating custom strings that can be used to protect sensitive information such as It will generate a 32-character hexadecimal MD5 hash string, regardless of the number of words/characters entered. Once the MD5 hash is created, it is irreversible and almost impossible to decrypt. The MD5 hash algorithm does not encrypt but encrypts the data and can be used after decryption.",
  },
  {
    id: "sha1",
    logo: images.sha1Logo,
    showUnixTime: false,
    title: "SHA1",
    route: Routes.sha1,
    routeArray: [Routes.sha1],
    footerHeader: "About SHA1",
    footerText:
      "The SHA-1 hash function generator creates a SHA1 hash that can be used as a secure 40-hex (160-bit) password or key to protect sensitive information such as credit cards and personal information. It will create a 40-character SHA1 hash string and is irreversible. SHA-1 is widely used to protect the security of SSL, SSH and other applications. ",
  },
  {
    id: "sha256",
    logo: images.sha1Logo,
    showUnixTime: false,
    title: "SHA256",
    route: Routes.sha256,
    routeArray: [Routes.sha256],
    footerHeader: "About SHA256",
    footerText:
      "This tool allows you to create a SHA256 (32 byte) hash of any entered string or value and return it as a 64-bit hexadecimal number. SHA stands for Secure Hash Algorithm, which is a cryptographic hash algorithm used to determine the integrity of a piece of information. SHA256 emerged as the successor to SHA1 as it is now more resistant to collisions due to its ability to produce long-term values  that are difficult to crack. This means SHA256 is well designed for hard hash authentication, fingerprinting, password verification, digital signature, marking data, and as a checksum to verify data collision.",
  },
  {
    id: "sha512",
    logo: images.sha1Logo,
    showUnixTime: false,
    title: "SHA512",
    route: Routes.sha512,
    routeArray: [Routes.sha512],
    footerHeader: "About SHA512",
    footerText:
      "The SHA512 hash function generator creates a SHA512 hash that can be used as a secure 128 (512 bit) character password or key to protect important information such as digital certificates, Internet security, or even business insurance. It will create a 128-character SHA512 hash string and is irreversible. SHA512 is one of the SHA-2 (Secure Hash Algorithm 2) series hash functions. SHA512 encryption is similar to SHA-256 except that it calculates a 512-bit digital fingerprint and generates 128 hexadecimal digits.",
  },
  {
    id: "base64Encode",
    logo: images.base64Logo,
    showUnixTime: false,
    title: "Base64 Encoder",
    route: Routes.base64Encode,
    routeArray: [Routes.base64Encode],
    footerHeader: "About Base64 Encoder",
    footerText:
      "The Base64 encoding scheme is often used when binary data needs to be encoded, especially when the data is to be stored and exported as text. This encoding helps ensure that data remains stable and not altered during transmission. Base64 is frequently used in many applications, including sending email via MIME and storing complex data in XML or JSON. Base64 is a general term for many similar encoding techniques that encode binary data by digitally processing it and converting it to a Base-64 representation. The term Base64 is derived from MIME context variable encoding.",
  },
  {
    id: "base64Decode",
    logo: images.base64Logo,
    showUnixTime: false,
    title: "Base64 Decoder",
    route: Routes.base64Decode,
    routeArray: [Routes.base64Decode],
    footerHeader: "About Base64 Decoder",
    footerText:
      "The Base64 encoding scheme is often used when binary data needs to be encoded, especially when the data is to be stored and exported as text. Base64 is frequently used in many applications, including sending email via MIME and storing complex data in XML or JSON. Base64 is a general term for many similar encoding techniques that encode binary data by digitally processing it and converting it to a Base-64 representation. This tool is used to decode Base64 text or binary online i.e. it converts Base64 to raw data.",
  },
];
