export const formatOptions: any = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: true,
  timeZoneName: "long",
};

export const formatTime = (timeString: string) => {
  const [hourString, minute, seconds] = timeString.split(":");
  const hour = +hourString % 24;
  return (
    (hour % 12 || 12) +
    ":" +
    (Number(minute) < 9 ? "0" + minute : minute) +
    ":" +
    (Number(seconds) < 9 ? "0" + seconds : seconds) +
    (hour < 12 ? " AM" : " PM")
  );
};

export const formatUtcDate = (u: Date) => {
  const formattedDate = new Date(
    (
      u.getUTCFullYear() +
      "-" +
      (u.getUTCMonth() + 1) +
      "-" +
      u.getUTCDate()
    ).replace(/-/g, "/")
  ).toLocaleString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "2-digit",
  });

  const formattedTime = formatTime(
    u.getUTCHours() + ":" + u.getUTCMinutes() + ":" + u.getUTCSeconds()
  );

  return `${formattedDate} \n${formattedTime?.toUpperCase()}`;
};
