import Button from "app/elements/button";
import DateTimePickerWrapper from "app/elements/dateTimePicker";
import DropDown from "app/elements/dropdown";
import { IResultCardProps } from "app/elements/resultCard";
import { formatOptions } from "helpers/dateTimeConverter";
import { timeZoneMenu } from "helpers/dropdown";
import moment from "moment";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import "./style.css";
import ResultSection from "app/components/resultSection";

export default function StartAndEndToYMD(props: {
  handleParentDropdown: Function;
  parentDropdownOpened: boolean;
}): ReactElement {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [dropdownValue, setDropdownValue] = useState(timeZoneMenu[0]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [results, setResults] = useState<IResultCardProps[]>([]);
  const startResultRef = useRef<any>(null);

  const handleDateChange = (date: any) => {
    setSelectedDate(new Date(date));
    setResults([]);
  };

  const handleDropdownChange = (selectedVal: {
    name: string;
    value: string;
  }) => {
    setDropdownValue(selectedVal);
    setOpenDropdown(false);
  };

  const handleDropdownOpen = () => {
    setOpenDropdown(!openDropdown);
    props.handleParentDropdown(false);
  };

  useEffect(() => {
    if (props.parentDropdownOpened) setOpenDropdown(false);
  }, [props.parentDropdownOpened]);

  const handleButtonClick = () => {
    if (selectedDate) {
      const selectedYear = new Date(selectedDate).getFullYear();

      const firstDay = new Date(selectedYear, 0, 1);
      const lastDay = new Date(selectedYear, 11, 31, 23, 59, 59);

      var firstEpochTS: any = moment(firstDay).format("X") || 0;
      var lastEpochTS: any = moment(lastDay).format("X") || 0;
      var tzDateFirst: any = firstDay
        .toLocaleString(undefined, formatOptions)
        .split(" at ");
      var tzDateLast: any = lastDay
        .toLocaleString(undefined, formatOptions)
        .split(" at ");

      var firstDateString = tzDateFirst[1]
        ? `${tzDateFirst[0]} \n${tzDateFirst[1]?.trim()?.toUpperCase()}`
        : tzDateFirst[0];

      var lastDateString = tzDateLast[1]
        ? `${tzDateLast[0]} \n${tzDateLast[1]?.trim()?.toUpperCase()}`
        : tzDateLast[0];

      var utcStringFirst = "",
        utcStringLast = "";

      if (dropdownValue.value === "gmt") {
        firstEpochTS = moment(firstDay).utc(true).format("X");
        lastEpochTS = moment(lastDay).utc(true).format("X");

        utcStringFirst = firstDateString.toString().split("GMT")[0];
        utcStringLast = lastDateString.toString().split("GMT")[0];
      }

      if (startResultRef && startResultRef.current) {
        startResultRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }

      setResults([
        {
          heading: "Start of year",
          subtitle: `${firstEpochTS} \n${
            dropdownValue.value === "gmt" ? utcStringFirst : firstDateString
          }`,
        },
        {
          heading: "End of year",
          subtitle: `${lastEpochTS} \n${
            dropdownValue.value === "gmt" ? utcStringLast : lastDateString
          }`,
        },
      ]);
    }
  };

  return (
    <React.Fragment>
      <div className="startAndEndToYMDWrapper">
        <DateTimePickerWrapper
          placeholder="Select DD/MM/YYYY"
          timeFormat={false}
          dateFormat="DD/MM/YYYY"
          handleDateChange={handleDateChange}
        />

        <DropDown
          handleChange={handleDropdownChange}
          selectedValue={dropdownValue}
          handleDropdownOpen={handleDropdownOpen}
          dropdownOptions={timeZoneMenu}
          openDropdown={openDropdown}
        />
        <div className="tsCta">
          <Button
            buttonText="Convert"
            handleButtonClick={handleButtonClick}
            isDisabled={!selectedDate}
          />
        </div>
      </div>

      {results.length > 0 &&
        selectedDate &&
        selectedDate?.toString()?.length > 0 && (
          <div className="pageResult" ref={startResultRef}>
            <ResultSection resultList={results} />
          </div>
        )}
    </React.Fragment>
  );
}
