import Button from "app/elements/button";
import DateTimePickerWrapper from "app/elements/dateTimePicker";
import DropDown from "app/elements/dropdown";
import { IResultCardProps } from "app/elements/resultCard";
import { formatOptions, formatUtcDate } from "helpers/dateTimeConverter";
import { timeZoneMenu } from "helpers/dropdown";
import moment from "moment";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import "./style.css";
import ResultSection from "app/components/resultSection";

export default function DateToTsConverter(props: {
  handleParentDropdown: Function;
  parentDropdownOpened: boolean;
}): ReactElement {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [dropdownValue, setDropdownValue] = useState(timeZoneMenu[0]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [results, setResults] = useState<IResultCardProps[]>([]);
  const dateResultRef = useRef<any>(null);

  const handleDateChange = (date: any) => {
    setSelectedDate(new Date(date));
    setResults([]);
  };

  const handleDropdownChange = (selectedVal: {
    name: string;
    value: string;
  }) => {
    setDropdownValue(selectedVal);
    setOpenDropdown(false);
  };

  const handleDropdownOpen = () => {
    setOpenDropdown(!openDropdown);
    props.handleParentDropdown(false);
  };

  useEffect(() => {
    if (props.parentDropdownOpened) setOpenDropdown(false);
  }, [props.parentDropdownOpened]);

  const handleButtonClick = () => {
    if (selectedDate) {
      var inputDate: any = new Date(selectedDate);
      var epochTS: any = moment(selectedDate).format("X") || 0;
      var tzDate = inputDate
        .toLocaleString(undefined, formatOptions)
        .split(" at ");

      var utcDate = formatUtcDate(inputDate);
      var utcString = "";
      if (dropdownValue.value === "gmt") {
        epochTS = moment(selectedDate).utc(true).format("X");
        utcString = selectedDate.toString().split("GMT")[0].concat("UTC");
        utcDate = formatUtcDate(new Date(utcString));
        tzDate = new Date(utcString)
          .toLocaleString(undefined, formatOptions)
          .split(" at ");
      }

      if (dateResultRef && dateResultRef.current) {
        dateResultRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }

      setResults([
        {
          heading: "Timestamp",
          subtitle: epochTS,
        },
        {
          heading: "Timestamp in ms",
          subtitle: `${epochTS * 1000}`,
        },
        {
          heading: "Date and Time",
          subtitle: utcDate,
        },
        {
          heading: "Date and Time (local)",
          subtitle: tzDate[1]
            ? `${tzDate[0]} \n${tzDate[1]?.trim()?.toUpperCase()}`
            : tzDate[0],
        },
      ]);
    }
  };

  return (
    <React.Fragment>
      <div className="dateToTsWrapper">
        <DateTimePickerWrapper
          placeholder="Select Date and Time"
          timeFormat="hh:mm:ss a"
          dateFormat="ddd, DD MMM YYYY"
          handleDateChange={handleDateChange}
        />

        <DropDown
          handleChange={handleDropdownChange}
          selectedValue={dropdownValue}
          handleDropdownOpen={handleDropdownOpen}
          dropdownOptions={timeZoneMenu}
          openDropdown={openDropdown}
        />

        <div className="tsCta">
          <Button
            buttonText="Convert"
            handleButtonClick={handleButtonClick}
            isDisabled={!selectedDate}
          />
        </div>
        <p className="tsInfo">
          Supports Unix timestamps in seconds, milliseconds, microseconds and
          nanoseconds.
        </p>
      </div>
      {results.length > 0 &&
        selectedDate &&
        selectedDate?.toString()?.length > 0 && (
          <div className="pageResult" ref={dateResultRef}>
            <ResultSection
              resultSubtitle="Assuming that this timestamp is in seconds"
              resultList={results}
            />
          </div>
        )}
    </React.Fragment>
  );
}
