import ResultCard, { IResultCardProps } from "app/elements/resultCard";
import { ReactElement } from "react";
import "./style.css";

export interface IResultSectionProps {
  resultSubtitle?: string;
  resultList: IResultCardProps[];
}

export default function ResultSection(
  props: IResultSectionProps
): ReactElement {
  const { resultSubtitle, resultList } = props;

  return (
    <div className="result-container">
      <div className="header">
        <p>
          Results
          {resultSubtitle && (
            <span className="green-line">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          )}
          <span className="res-subtitle">{resultSubtitle}</span>
        </p>
      </div>

      <div className="divider" />

      <div className="result-wrapper">
        {resultList.map((item, index) => (
          <ResultCard
            key={index}
            heading={item?.heading}
            subtitle={item?.subtitle}
            customCardStyles={item?.customCardStyles}
            customSubtitleStyles={item?.customSubtitleStyles}
          />
        ))}
      </div>
    </div>
  );
}
