import { ReactElement } from "react";
import "./style.css";

export interface IButtonProps {
  isDisabled?: boolean;
  buttonText: string;
  handleButtonClick: (event: any) => void;
}

export default function Button(props: IButtonProps): ReactElement {
  const { isDisabled = false, buttonText, handleButtonClick } = props;

  return (
    <button
      disabled={isDisabled}
      className={isDisabled ? "disabled" : ""}
      onClick={handleButtonClick}
    >
      {buttonText}
    </button>
  );
}
