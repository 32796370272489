import { ReactElement } from "react";
import "./style.css";
import { images } from "assets/images";
import InfoSection, { IInfoSectionProps } from "../infoSection";
import { hamburgerComponents } from "helpers/dropdown";

export default function SiteFooter(props: IInfoSectionProps): ReactElement {
  const { infoDetails, infoHeader } = props;

  return (
    <div className="footer-container">
      <div className="footer-text-container">
        <div className="footer-title-container">
          <p className="footer-header">Tools</p>
          <div className="footer-header-row">
            <div>
              {hamburgerComponents.slice(0, 4).map((i, index) => (
                <div className="footer-title" key={index + " " + i.title}>
                  <a href={i.route}>{i.title}</a>
                </div>
              ))}
            </div>

            <div>
              {hamburgerComponents.slice(4, 8).map((i, index) => (
                <div className="footer-title" key={index + " " + i.title}>
                  <a href={i.route}>{i.title}</a>
                </div>
              ))}
            </div>
          </div>

          <div className="footer-header-row footer-header-row-mob">
            <div>
              {hamburgerComponents.map((i, index) => (
                <div className="footer-title" key={index + " " + i.title}>
                  <a href={i.route}>{i.title}</a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="footer-img-container">
          <img
            src={images.navbarLogo}
            alt="logo"
            title="logo"
            width={152}
            height={114}
          />
        </div>
      </div>
      <div className="footer-copyright-container">
        <InfoSection infoHeader={infoHeader} infoDetails={infoDetails} />
      </div>
    </div>
  );
}
