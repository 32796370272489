import { images } from "assets/images";
import { ReactElement } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "./style.css";

export interface IDatePickerProps {
  timeFormat?: string | boolean;
  dateFormat?: string | boolean;
  placeholder?: string;
  handleDateChange: any;
}

export default function DateTimePickerWrapper(
  props: IDatePickerProps
): ReactElement {
  const { timeFormat, dateFormat, placeholder, handleDateChange } = props;

  return (
    <label className="datePickerWrapper">
      <div className="picker">
        <Datetime
          timeFormat={timeFormat}
          dateFormat={dateFormat}
          inputProps={{
            placeholder,
            disabled: false,
            readOnly: true,
          }}
          onChange={handleDateChange}
        />
      </div>
      <img src={images.selectDownIcon} width={10} height={5} alt="down" />
    </label>
  );
}
