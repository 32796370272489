import { images } from "assets/images";
import { Routes } from "helpers/routes";
import { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { hamburgerComponents } from "helpers/dropdown";

export interface ICurrentComponentProps {
  id: string;
  logo: string;
  showUnixTime: boolean;
  title: string;
  route: Routes;
  routeArray: Routes[];
  footerHeader: string;
  footerText: string;
}

export interface ISiteNavbarProps {
  setShowNavbar?: Function;
  showClose?: boolean;
  showInitialAnim?: boolean;
}

export default function SiteNavbar(props: ISiteNavbarProps): ReactElement {
  const { setShowNavbar, showClose = true, showInitialAnim = true } = props;

  const [showNavbarExitAnim, setShowNavbarExitAnim] = useState<boolean>(false);
  const [pathName, setPathName] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();

  function handleNavClose() {
    if (setShowNavbar && showClose) {
      setShowNavbarExitAnim(true);

      setTimeout(() => {
        setShowNavbar(false);
      }, 500);
    }
  }

  function handleNavTitleClick(clickedItem: ICurrentComponentProps) {
    navigate("/" + clickedItem.route);
    handleNavClose();
  }

  useEffect(() => {
    if (location) {
      let tmp = location.pathname.split("/")[1];
      setPathName(tmp);
    }
  }, [location]);

  return (
    <div
      className={
        showNavbarExitAnim
          ? "nav-container slideInUp"
          : showInitialAnim
          ? "nav-container slideInDown"
          : "nav-container error-nav-container"
      }
    >
      <div
        className={
          showNavbarExitAnim
            ? "navtitle-wrapper hide-container"
            : showInitialAnim
            ? "navtitle-wrapper fadeInUp delayed"
            : "navtitle-wrapper"
        }
      >
        {showClose && (
          <div className="nav-close" onClick={handleNavClose}>
            <div className="close-line"></div>Close
          </div>
        )}
        <div className="navtitle-container">
          {hamburgerComponents.map((item) => (
            <div
              key={item.id}
              className={
                item.routeArray.includes(pathName)
                  ? "nav-title selected-nav"
                  : "nav-title"
              }
              onClick={() => handleNavTitleClick(item)}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
      <div
        className={
          showInitialAnim
            ? "navlogo-wrapper fadeInUp delayed"
            : "navlogo-wrapper"
        }
      >
        <img
          src={images.navbarLogo}
          alt="logo"
          title="logo"
          className="nav-logo"
        />
      </div>
    </div>
  );
}
