import { images } from "assets/images";
import React, { ReactElement } from "react";
import "./style.css";

export interface IDropdownProps {
  handleChange: (event: any) => void;
  selectedValue: { name: string; value: string };
  handleDropdownOpen: (event: any) => void;
  dropdownOptions: { name: string; value: string }[];
  openDropdown: boolean;
}

export default function DropDown(props: IDropdownProps): ReactElement {
  const {
    selectedValue,
    handleChange,
    handleDropdownOpen,
    dropdownOptions,
    openDropdown,
  } = props;

  const mountedStyle = { animation: "inAnimation 500ms ease-out" };
  const unmountedStyle = {
    animation: "outAnimation 270ms ease-out",
  };

  return (
    <div style={openDropdown ? mountedStyle : unmountedStyle}>
      {!openDropdown ? (
        <div className="dropdownContainer closedContainer">
          <div className="selectedItem" onClick={handleDropdownOpen}>
            <div className="selected">{selectedValue.name}</div>
            <img src={images.selectDownIcon} width={10} height={5} alt="down" />
          </div>
        </div>
      ) : (
        <div className="dropdownContainer">
          <div className="selectedItem" onClick={handleDropdownOpen}>
            <div className="selected">{selectedValue.name}</div>
            <img src={images.selectupIcon} width={18} height={10} alt="up" />
          </div>
          {openDropdown &&
            dropdownOptions?.map((menuItem) => (
              <div
                className="menuItem"
                key={menuItem.value}
                onClick={() => handleChange(menuItem)}
              >
                <div className="title">{menuItem.name}</div>
                {selectedValue.value === menuItem.value && (
                  <img
                    src={images.tickIcon}
                    width={24}
                    height={24}
                    alt="tick"
                  />
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
