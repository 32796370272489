import ResultSection from "app/components/resultSection";
import Button from "app/elements/button";
import Input from "app/elements/input";
import { IResultCardProps } from "app/elements/resultCard";
import CryptoJS from "crypto-js";
import React, { ReactElement, useRef, useState } from "react";
import "./style.css";

export default function Sha512Converter(): ReactElement {
  const [inputValue, setInputValue] = useState<string>("");
  const [results, setResults] = useState<IResultCardProps[]>([]);
  const sha512 = useRef<any>(null);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
    setResults([]);
  };

  function handleButtonClick() {
    if (sha512 && sha512.current) {
      sha512.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    setResults([
      {
        heading: "SHA512",
        subtitle: `${CryptoJS.SHA512(inputValue)}`,
      },
    ]);
  }
  return (
    <React.Fragment>
      <Input
        placeholder="Type here"
        handleChange={handleInputChange}
        inputValue={inputValue}
      />
      <div className="md5Cta">
        <Button
          buttonText="Generate"
          handleButtonClick={handleButtonClick}
          isDisabled={!inputValue}
        />
      </div>

      {results.length > 0 && inputValue.length > 0 && (
        <div className="pageResult" ref={sha512}>
          <ResultSection resultList={results} />
        </div>
      )}
    </React.Fragment>
  );
}
