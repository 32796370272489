import AppLandingPage from "./modules/appLandingPage";
import EpochConverter from "./modules/epochConverter";
import MD5HashConverter from "./modules/md5HashConverter";
import "./style.css";

import { Routes } from "helpers/routes";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Base64Decoder from "./modules/base64Decoder";
import Base64Encoder from "./modules/base64Encoder";
import Sha1Converter from "./modules/sha1Converter";
import Sha256Converter from "./modules/sha256Converter";
import Sha512Converter from "./modules/sha512Converter";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLandingPage />,
    errorElement: <AppLandingPage errorComponent={true} />,
    children: [
      {
        path: Routes.dateToTimeStamp,
        element: <EpochConverter />,
      },
      {
        path: Routes.timeStampToDate,
        element: <EpochConverter />,
      },
      {
        path: Routes.secToDHM,
        element: <EpochConverter />,
      },
      {
        path: Routes.startAndEndToYMD,
        element: <EpochConverter />,
      },
      {
        path: Routes.md5Hash,
        element: <MD5HashConverter />,
      },
      {
        path: Routes.sha1,
        element: <Sha1Converter />,
      },
      {
        path: Routes.sha256,
        element: <Sha256Converter />,
      },
      {
        path: Routes.sha512,
        element: <Sha512Converter />,
      },
      {
        path: Routes.base64Decode,
        element: <Base64Decoder />,
      },
      {
        path: Routes.base64Encode,
        element: <Base64Encoder />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
