import SiteNavbar, { ICurrentComponentProps } from "app/components/siteNavbar";
import { images } from "assets/images";
import { Routes } from "helpers/routes";
import React, { ReactElement, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import EpochConverter from "../epochConverter";
import "./style.css";
import SiteFooter from "app/components/footer";
import { hamburgerComponents } from "helpers/dropdown";
import { Helmet } from "react-helmet";

export default function AppLandingPage({
  errorComponent = false,
}: any): ReactElement {
  const [unixEpochTime, setUnixEpochTime] = useState(getCurrentTime());
  const [showNavbar, setShowNavbar] = useState<boolean>(false);
  const [currentNavComponent, setCurrentNavComponent] =
    useState<ICurrentComponentProps>(hamburgerComponents[0]);
  const [pathName, setPathName] = useState("");
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setInterval(() => {
      setUnixEpochTime(getCurrentTime());
    }, 1000);
  });

  function getCurrentTime() {
    var date = new Date();
    return (date.getTime() - date.getMilliseconds()) / 1000;
  }

  function handleHumburgerClick() {
    setShowNavbar(!showNavbar);
  }

  useEffect(() => {
    if (errorComponent) setShowNavbar(true);
  }, [errorComponent]);

  useEffect(() => {
    if (location) {
      let tmp: any = location.pathname.split("/")[1];
      setPathName(tmp);
      setCurrentNavComponent(
        hamburgerComponents.find((el) => el.routeArray.includes(tmp)) ||
          hamburgerComponents[0]
      );
    }
  }, [location]);

  function handleCopyClick() {
    navigator.clipboard.writeText(unixEpochTime?.toString() || "");
    setShowModal(true);

    setTimeout(() => {
      setShowModal(false);
    }, 1000);
  }

  return (
    <div>
      <Helmet>
        <title>{currentNavComponent.title}</title>
        <meta name="description" content={currentNavComponent.footerText} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {showNavbar && (
        <div className="nav-popup">
          <SiteNavbar
            setShowNavbar={setShowNavbar}
            showClose={!errorComponent}
            showInitialAnim={!errorComponent}
          />
        </div>
      )}

      <div className="lpPageContainer">
        <div className="epoc-header">
          {!showNavbar && (
            <React.Fragment>
              <img
                src={images.menuIcon}
                width="44"
                height="44"
                alt="Menu"
                title="Menu"
                className="menu-icon"
                onClick={handleHumburgerClick}
              />
              <img
                src={images.menuIconMob}
                width="16"
                height="16"
                alt="Menu"
                title="Menu"
                className="menu-icon menu-icon-mob"
                onClick={handleHumburgerClick}
              />
            </React.Fragment>
          )}
          {currentNavComponent.showUnixTime && (
            <div className="unixTime" onClick={handleCopyClick}>
              <React.Fragment>{unixEpochTime}</React.Fragment>
              <span>Current Unix Epoch Time</span>
            </div>
          )}
          {showModal && (
            <div className="popup header-popup">
              <p>Copied!</p>
            </div>
          )}
          <div className="pageHeader">
            <img
              src={currentNavComponent.logo}
              width="44"
              height="44"
              alt="logo"
              title="logo"
              className="pageHeader-logo"
            />
            <span className="pageHeader-title">
              {currentNavComponent.title?.toUpperCase()}
            </span>
          </div>
        </div>
        <div className="lp-header-container">
          <div className="pageBody">
            {pathName === Routes.home ? <EpochConverter /> : <Outlet />}
          </div>
          <SiteFooter
            infoHeader={currentNavComponent.footerHeader}
            infoDetails={currentNavComponent.footerText}
          />
        </div>
      </div>
    </div>
  );
}
